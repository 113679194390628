import React from "react";
import styled from "styled-components";
import { ScContainer, ScSection, ScDivider } from "../components/Style";
import IconDiscovery from "../imgs/Discovery.svg";
import IconEngineering from "../imgs/Engineering.svg";
import IconProductDesign from "../imgs/ProductDesign.svg";
import IconProductStrategy from "../imgs/ProductStrategy.svg";

const ScIntro = styled(ScSection)`
  max-width: 720px;
`;

const ScInnovation = styled(ScSection)`
  max-width: 980px;
`;

const ScInnovationList = styled.div`
  margin-top: 2rem;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
  }
`;
const ScInnovationItem = styled.div`
  margin-bottom: 2rem;

  p {
    max-width: 380px;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`;

export const Intro = () => {
  return (
    <ScContainer>
      <ScIntro>
        <h2>
          We are a risk management consulting company that transforms concepts
          into strategies.
        </h2>
        <p className="lg">
          We partner with organizations to design and implement risk management
          solutions that are effective, efficient, and easy to use.
        </p>
      </ScIntro>

      <ScInnovation>
        <ScDivider>How we can help</ScDivider>
        <h2>Comprehensive risk management solutions</h2>
        <p className="lg">
          We understand that every business faces unique challenges when it
          comes to managing risk. That's why we have a team of experts with a
          range of skills, including risk assessment, strategy development, and
          implementation.
        </p>

        <ScInnovationList>
          <ScInnovationItem>
            <img src={IconDiscovery} alt="" width={81} height={72} />
            <h3>Risk Management</h3>
            <p className="md">
              By developing and implementing effective risk management
              strategies, we can help your company minimize potential losses and
              maximize success.
            </p>
          </ScInnovationItem>
          <ScInnovationItem>
            <img src={IconProductStrategy} alt="" width={78} height={72} />
            <h3>Risk Strategy</h3>
            <p className="md">
              A one-size-fits-all approach to risk management may not be
              effective for every organization, we develop custom risk
              strategies.
            </p>
          </ScInnovationItem>
          <ScInnovationItem>
            <img src={IconProductDesign} alt="" width={53} height={72} />
            <h3>Product Design</h3>
            <p className="md">
              Our product design team specializes in creating innovative
              solutions that help organizations effectively manage risk.
            </p>
          </ScInnovationItem>
          <ScInnovationItem>
            <img src={IconEngineering} alt="" width={69} height={72} />
            <h3>Engineering</h3>
            <p className="md">
              We offer a range of engineering services, including risk
              assessment, strategy development, and implementation support.
            </p>
          </ScInnovationItem>
        </ScInnovationList>
      </ScInnovation>
    </ScContainer>
  );
};
