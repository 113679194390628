import firebase from "firebase/compat/app";

var firebaseConfig = {
  apiKey: "AIzaSyDyag1oRp-uvB52IwcR8U9bsHGTgl74zxY",
  authDomain: "powerpro-bc3a5.firebaseapp.com",
  projectId: "powerpro-bc3a5",
  storageBucket: "powerpro-bc3a5.appspot.com",
  messagingSenderId: "629221967786",
  appId: "1:629221967786:web:67595728c88f8f5de1cdd3",
  measurementId: "G-DMNTS87GFB",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
