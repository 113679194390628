import styled from "styled-components";

export const ScContainer = styled.div`
  max-width: 1024px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
`;

export const ScSection = styled.section`
  margin-top: 4rem;
  margin-bottom: 4rem;

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  p.lg {
    font-size: 20px;
  }
  p.md {
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 8rem;
    margin-bottom: 8rem;

    h2 {
      font-size: 32px;
    }
    h3 {
      font-size: 22px;
      margin-bottom: 12px;
    }

    p.lg {
      font-size: 24px;
    }
    p.md {
      font-size: 22px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
`;

export const ScDivider = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 3rem;
  color: #5f5f5c;

  &:after {
    margin-top: 1rem;
    content: " ";
    display: block;
    width: 50px;
    height: 1px;
    background-color: #ff646f;
  }
`;
