import React from "react";
import "../firebase";

import { Layout } from "../components/Layout";
import { Banner } from "../components/Banner";
import { Intro } from "../components/Intro";
import { GetInTouch } from "../components/GetInTouch";
import { About } from "../components/About";

export default function Home() {
  return (
    <Layout>
      <Banner />
      <Intro />
      <GetInTouch />
      <About />
    </Layout>
  );
}
