import React from "react";
import styled from "styled-components";
import { ScContainer } from "../components/Style";

const ScBanner = styled.div`
  background-color: #15171e;
  color: white;
`;

const ScHeader = styled.header`
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 28px;
  font-weight: 300;

  a {
    text-decoration: none;
    color: white;
  }

  svg {
    width: 28px;
  }
`;

const ScBannerBody = styled.div`
  padding-top: 12rem;
  padding-bottom: 12rem;
  font-size: 42px;
  max-width: 720px;
  line-height: 1.2;
  overflow-x: hidden;
  margin-bottom: 24px;

  em {
    font-size: 18px;
    display: block;
    font-weight: 300;
    opacity: 0.5;
    font-style: normal;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 64px;

    em {
      font-size: 24px;
    }
  }
`;

const ScButton = styled.a`
  display: inline-block;
  width: 180px;
  height: 52px;
  text-align: center;
  background-color: #ff646f;
  color: white;
  font-size: 18px;
  line-height: 52px;
  text-decoration: none;
`;

export const Banner = () => {
  return (
    <ScBanner>
      <ScContainer>
        <ScHeader>
          <a href="/">
            <img src="./assets/images/DePower.png" alt="DePower" height="32" />
          </a>
        </ScHeader>
        <ScBannerBody>
          <em>Advanced risk management consulting</em>
          <p>We strategize, design & implement risk solutions</p>
          <ScButton href="/#get-in-touch">Learn More</ScButton>
        </ScBannerBody>
      </ScContainer>
    </ScBanner>
  );
};
