import React from "react";
import styled from "styled-components";
import { ScContainer, ScSection } from "../components/Style";

const ScMain = styled(ScSection)`
  border-top: 1px solid #b5b6bc;
  margin-bottom: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #020202;

  a {
    text-decoration: none;
  }
  b {
    color: #ff646f;
  }
`;

const ScList = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;
const ScGroup = styled.div`
  margin-right: 20px;
`;

export const Footer = () => {
  return (
    <ScMain>
      <ScContainer>
        <ScList>
          <ScGroup>
            Email:{" "}
            <a href="mailto:admin@depower.co">
              <b>admin@depower.co</b>
            </a>
          </ScGroup>
        </ScList>
        <p>All Rights Reserved DePower</p>
      </ScContainer>
    </ScMain>
  );
};
