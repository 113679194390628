import React from "react";
import styled from "styled-components";
import { ScContainer, ScSection, ScDivider } from "../components/Style";

const ScMain = styled(ScSection)``;
const ScBody = styled.div`
  max-width: 720px;
`;

export const About = () => {
  return (
    <ScMain>
      <ScContainer>
        <h2>About DePower</h2>
        <ScDivider />

        <ScBody>
          <h4>We are a risk management consultancy committed to excellence.</h4>
          <p>
            We strategize, design, and implement customized risk management
            solutions that help organizations effectively manage risk and
            achieve their goals. Our team of experts is passionate about helping
            clients navigate the complex world of risk management, and we
            believe our work can have a positive impact on the success and
            stability of businesses.
          </p>
          <p>
            We handle every aspect of risk management, from assessment and
            strategy development to implementation and ongoing support.
          </p>
        </ScBody>
      </ScContainer>
    </ScMain>
  );
};
