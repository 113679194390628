import React, { useState } from "react";
import styled from "styled-components";
import { ScContainer, ScSection } from "../components/Style";
import { createSubscription } from "../firebase/firestore";

const ScMain = styled(ScSection)`
  background-image: url("./assets/images/x-group-9.jpg");
  background-size: cover;
  color: white;

  @media screen and (min-width: 1024px) {
    min-height: 800px;
    height: 800px;
  }
`;

const ScForm = styled.div`
  max-width: 440px;
  margin-left: auto;
  padding: 1.2rem;
  background-color: #15171e;

  @media screen and (min-width: 1024px) {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 2rem 3rem;
  }
`;
const ScFormContainer = styled.div`
  margin-bottom: 2rem;
`;
const ScFormHeader = styled.div`
  margin-bottom: 36px;
`;
const ScFormBody = styled.div`
  margin-bottom: 36px;
`;

const ScInputGroup = styled.div`
  margin-bottom: 20px;
`;
const ScLabel = styled.label`
  display: block;
  margin-bottom: 8px;

  em {
  }
`;
const ScInput = styled.input`
  width: 100%;
  height: 36px;
  border: 1px solid #272c3b;
  background: #0f1116;
  color: white;
  padding-left: 12px;

  &:focus,
  &:active {
    border-color: #ff646f;
    outline: #ff646f;
  }
`;

const ScButton = styled.button`
  display: inline-block;
  width: 100%;
  height: 52px;
  text-align: center;
  background-color: #ff646f;
  color: white;
  font-size: 18px;
  line-height: 52px;
  outline: none;
  border: 0;
  cursor: pointer;
`;

export const GetInTouch = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submit = async () => {
    console.log(
      "firstName, lastName, company, email",
      firstName,
      lastName,
      company,
      email
    );
    try {
      await createSubscription(firstName, lastName, company, email);
      setIsSubmitted(true);
    } catch (err) {
      setIsSubmitted(true);
      console.log("err", err);
    }
  };

  return (
    <ScMain id="get-in-touch">
      <ScContainer style={{ height: "100%" }}>
        <ScForm>
          <ScFormContainer>
            <ScFormHeader>
              <h2 style={{ marginBottom: 12 }}>Get in touch with us!</h2>
              <p>
                Fill in your details and one of our staff members will get back
                to you soon.
              </p>
            </ScFormHeader>

            {!isSubmitted ? (
              <>
                <ScFormBody>
                  <ScInputGroup>
                    <ScLabel>First Name</ScLabel>
                    <ScInput
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </ScInputGroup>
                  <ScInputGroup>
                    <ScLabel>Last Name</ScLabel>
                    <ScInput
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </ScInputGroup>
                  <ScInputGroup>
                    <ScLabel>Company</ScLabel>
                    <ScInput
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </ScInputGroup>
                  <ScInputGroup>
                    <ScLabel>Email Address</ScLabel>
                    <ScInput
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </ScInputGroup>
                </ScFormBody>
                <ScButton
                  onClick={() => {
                    submit();
                    return false;
                  }}
                >
                  Contact Us
                </ScButton>
              </>
            ) : (
              <p>
                Thank you for reaching out. We've received your information. We
                will contact you shortly.
              </p>
            )}
          </ScFormContainer>
        </ScForm>
      </ScContainer>
    </ScMain>
  );
};
