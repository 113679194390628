import { collection, getFirestore, addDoc } from "firebase/firestore";

const SUBSCRIPTION_COLLECTION_NAME = "tenforward";

const db = getFirestore();

export const SubscriptionCollection = collection(
  db,
  SUBSCRIPTION_COLLECTION_NAME
);

export const createSubscription = async (
  firstName,
  lastName,
  company,
  email
) => {
  return addDoc(SubscriptionCollection, {
    firstName,
    lastName,
    company,
    email,
    createdAt: Date.now(),
    source: "tenforward",
  });
};
