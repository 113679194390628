import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

import { Footer } from "./Footer";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: SFProDisplay;
    src: url(./fonts/SF-Pro/SFPRODISPLAYREGULAR.OTF) format('otf');
    font-weight: normal;
    font-display: block;
  }
  @font-face {
    font-family: SFProDisplay;
    src: url(./fonts/SF-Pro/SFPRODISPLAYBOLD.OTF) format('otf');
    font-weight: bold;
    font-display: block;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }

  body {
    font-family: SFProDisplay,system-ui,-apple-system,segoe ui,Roboto,Helvetica,Arial,sans-serif;
    line-height: 1.5;
    margin: 0;

    h1, h2, h3, h4, h5, h6 {
      line-height: 1.2;
      font-weight: bold;
    }
  }

  @media only screen and (min-width: 1024px) {
    html {
      font-size: 16px;
    }
  }
`;

const Main = styled.main``;

export const Layout = ({ children }) => {
  return (
    <Main>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <title>DePower</title>

        <meta name="referrer" content="no-referrer-when-downgrade" />
        <meta name="HandheldFriendly" content="True" />
        <meta
          name="description"
          content="We strategize, design & create digital experiences"
        />

        <meta property="og:site_name" content="DePower" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="DePower" />
        <meta
          property="og:description"
          content="We strategize, design & create digital experiences"
        />
        <meta property="og:url" content="https://power.pro" />
        <meta
          property="og:image"
          content="/assets/images/TenForwardSocial.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="DePower" />
        <meta
          name="twitter:description"
          content="We strategize, design & create digital experiences"
        />
        <meta name="twitter:url" content="https://power.pro" />
        <meta
          name="twitter:image"
          content="/assets/images/TenForwardSocial.jpg"
        />
        <meta name="twitter:site" content="@PowerTradeHQ" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <link
          rel="preload"
          href="./fonts/SF-Pro/SFPRODISPLAYREGULAR.OTF"
          as="font"
          type="font/otf"
        />
        <link
          rel="preload"
          href="./fonts/SF-Pro/SFPRODISPLAYBOLD.OTF"
          as="font"
          type="font/otf"
        />

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#15171E" />

        <link
          rel="stylesheet"
          href="https://necolas.github.io/normalize.css/latest/normalize.css"
        ></link>
      </Helmet>

      <GlobalStyle />

      {children}

      <Footer />
    </Main>
  );
};
